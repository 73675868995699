<template lang="pug">
  b-nav-item-dropdown.lm-header-account(
    toggle-class='d-flex align-items-center pr-0'
    menu-class='position-absolute rounded-0 border-0 m-0 p-0'
    no-caret
    right)
    template(slot='button-content')
      .d-flex.align-items-center.dropdown-toggle-rounded.my-2.p-lg-2(v-if='panelShow')
        span.small.text-white.mx-3
          | {{ $t('account.hello') }},
          strong()
            |  {{ panel.data.attributes.username || $t('account.no_name') }}
        b-avatar.wc-bubble.wc-bubble-xxs.wc-bubble-lg-xs.wc-bubble-bl.shadow-xs(
          :src='panel.data.attributes.portrait_path'
          variant='white'
          alt='')
          template(v-if='!panel.data.attributes.portrait_path' #default)
            .wc-bubble-content.wc-bubble-linked.bg-secondary
              .i-wrapper
                i.fal.fa-fw.fa-lg-15x.fa-user.text-white
      .d-flex.align-items-center.dropdown-toggle-rounded.my-2.p-lg-2(v-else)
        span.small.text-white.mx-3
          | {{ $t('account.hello') }}, {{ $t('account.no_name') }}
        b-avatar.wc-bubble.wc-bubble-xxs.wc-bubble-lg-xs.wc-bubble-bl.shadow-xs(
          variant='white'
          alt='')
          .wc-bubble-content.wc-bubble-linked.bg-secondary
            .i-wrapper
              i.fal.fa-fw.fa-lg-15x.fa-user.text-white
    template(v-if='panelShow')
      b-dropdown-item(
        link-class='py-3'
        :to="{ name: 'manager-account-edit' }"
        :active="exact('/manager/account')")
        i.fal.fa-btn.fa-fw.fa-key
        |  {{ $t('account.menus.my_account') }}
      b-dropdown-item(
        link-class='py-3'
        :to="{ name: 'logout' }"
        exact)
        i.fal.fa-btn.fa-fw.fa-unlock
        |  {{ $t('session.sign_out') }}
</template>

<script>
import { Panel } from '@common/panel'
import { Menu } from '@common/menu'

export default {
  name: 'lm-header-account',
  mixins: [Panel, Menu],
  methods: {
    cachePayloadFunc() {
      return { account_id: this.$store.getters['auth/currentAccount'].sub }
    },
  },
  data() {
    return {
      cacheType: 'account/getPanel',
      cacheOptions: { timeout: this.$wc.conf.cache.session },
      skipNotifications: true,
    }
  },
}
</script>
