const notify = {
  methods: {
    notifyDispatch(response, options = {}) {
      if (this.$getDeep(response, 'meta.notices')) {
        for (let notice of this.$getDeep(response, 'meta.notices')) {
          this.$noty[notice.type](notice.detail, {
            title: notice.title,
            ...options,
          })
        }
      }
    },
  },
}

export { notify }
