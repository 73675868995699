<template lang="pug">
  b-nav-item-dropdown.wc-language-switch(
    menu-class='p-0'
    toggle-class='list-group-item-action d-flex align-items-center px-4 py-3 text-body'
    dropup
    right
    no-caret)
    template(#button-content)
      i.fal.fa-fw.fa-25w.fa-15x.fa-flag.flex-shrink-0.text-secondary
      span.pl-3.font-weight-bold
        | {{ $t(`locales.${locale}`) }}
    b-dropdown-item(
      v-for='locale in locales'
      :key='locale'
      link-class='py-3'
      @click.stop.prevent='onLanguage(locale)'
      v-b-toggle="['main-account-tools', 'main-organization-tools']")
      | {{ $t(`locales.${locale}`) }}
</template>

<script>
export default {
  name: 'wc-language-switch',
  computed: {
    locale() {
      /* This $root is used because of a bug on server side. */
      return this.$root.$store.getters['ui/getLocale']
    },
    locales() {
      return this.$wc.conf.base.available_locales
    },
  },
  methods: {
    onLanguage(value) {
      if (!this.$wc.conf.base.available_locales.includes(value)) return

      if (this.$store.getters['ui/getLocale'] !== value) {
        if (!this.$store.getters['ui/getLocalesLoaded'][value].length) {
          return this.$store
            .dispatch('ui/loadLocale', {
              locale: value,
              query: '.',
            })
            .then(() => {
              this.setLocale(value)
              return
            })
        } else {
          this.setLocale(value)
        }
      }
    },
    setLocale(value) {
      this.$store.cache.clear()
      this.$store.dispatch('ui/setLocale', value)
    },
  },
}
</script>
