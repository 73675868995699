<template lang="pug">
  layout-manager
    router-view
</template>

<script>
import LayoutManager from '@components/layouts/Manager'

export default {
  name: 'manager',
  components: {
    LayoutManager,
  },
}
</script>
