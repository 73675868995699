export const Menu = {
  methods: {
    starts(path) {
      /* This $root is used because of a bug on server side. */
      return this.$root.$route.path.startsWith(path)
    },
    match(path) {
      /* This $root is used because of a bug on server side. */
      return new RegExp(`^${path}`).test(this.$root.$route.path)
    },
    exact(path) {
      /* This $root is used because of a bug on server side. */
      return this.$root.$route.path.replace(/\/$/, '') === path
    },
  },
}
