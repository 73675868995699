<template lang="pug">
  transition-group(:name='transition')
    b-alert.wc-forms-alerts(show :variant='variant' v-for='msg in (errors || [message])' :key='msg.id')
      .row
        .col-1.alert-icon-col.d-flex.align-items-center
          i.fa-fw.fa-2x(:class='icon')
        .col
          b.d-block(v-if='msg.title')
            | {{ msg.title }}
          span(v-if='msg.detail')
            | {{ msg.detail }}
</template>

<script>
export default {
  name: 'wc-forms-alerts',
  props: {
    transition: {
      type: String,
      default: 'fade',
    },
    variant: {
      type: String,
      default: 'danger',
    },
    icon: {
      type: String,
      default: 'fal fa-exclamation-circle',
    },
    errors: Array,
    message: Object,
  },
}
</script>

<style lang="scss">
.wc-forms-alerts .alert-icon-col {
  min-width: 50px;
  max-width: 50px;
  padding-right: 0;
}
</style>
